import React from 'react'
import { siteMetadata } from '../../gatsby-config'
import Layout from 'components/layout'
import Meta from 'components/meta'
import Icon from 'components/icon'

const ContactUs = ({location}) => {
  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Contact Us" />
      <div className="container">
        <div className="row">
        <div className="col-sm">
            <h1 className="my-4">Visit Us</h1>
            <a href="https://www.google.com/maps/place/37+Industry+Pl,+Bayswater+VIC+3153/@-37.8425055,145.2759371,15z/data=!4m5!3m4!1s0x6ad63b0ca869038d:0x612dd1df9c2ca5f0!8m2!3d-37.8426248!4d145.2759163">
              37 Industry Place,
              <br />
              Bayswater, Victoria, 3153
              <br />
              Australia
            </a>
          </div>
          <div className="col-sm">
            <h1 className="my-4">Message Us</h1>
            <form
              method="post"
              action="https://formspree.io/info@highqflatpack.com.au"
            >
              <div className="form-group">
                <label htmlFor="_replyto">Email</label>
                <input
                  type="email"
                  className="form-control"
                  name="_replyto"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
                <small id="emailHelp" class="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div className="form-group">
                <label>Message</label>
                <textarea
                  className="form-control"
                  name="_message"
                  placeholder="Enter message (optional)"
                  rows="5"
                />
              </div>
              <input type="hidden" name="_cc" value="imliviu@gmail.com" />
              <input className="btn btn-primary" type="submit" value="Send" />
            </form>
          </div>
       
          <div className="col-sm">
            <h1 className="my-4">Call Us</h1>

            <a href="tel:1300 00 47 37">1300 00 47 37</a>
          </div>
        </div>
      </div>
    </Layout>
  )}

  export default ContactUs